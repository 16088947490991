import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        levels: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('levels', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        locations: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('locations', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    }
}
