<template>
    <div>
        <b-row v-if="align == 'horizontal'">
            <b-col
                v-for="(filter, index) in filters"
                :key="filter.level"
                cols="12"
                :md="parseInt(12 / filters.length) + parseInt(index == 0? 12 % filters.length : 0)"
                class="mb-md-0 mb-2"
            >
                <label :for="filter.level">{{ filter.label }}</label>
                <b-form-select
                    :id="filter.level"
                    :name="filter.level"
                    :size="size"
                    v-model="filter.location"
                    @change="changeFilters(filter.location, index)"
                >
                    <b-form-select-option :value="null">Select...</b-form-select-option>
                    <b-form-select-option
                        v-for="location in filter.locations"
                        :key="location.id"
                        :value="location"
                    >
                        {{ location.name.toUpperCase() }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>

        <b-row v-if="align == 'vertical'" v-for="(filter, index) in filters" :key="filter.level">
            <b-col cols="12" class="mb-md-0 mb-2">
                <label :for="filter.level">{{ filter.label }}</label>
                <b-form-select
                    :id="filter.level"
                    :name="filter.level"
                    :size="size"
                    v-model="filter.location"
                    @change="changeFilters(filter.location, index)"
                >
                    <b-form-select-option :value="null">Select...</b-form-select-option>
                    <b-form-select-option
                        v-for="location in filter.locations"
                        :key="location.id"
                        :value="location"
                    >
                        {{ location.name.toUpperCase() }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BFormSelect,
    BFormSelectOption,
 } from 'bootstrap-vue'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import locationFiltersStoreModule from '@core/components/cromis/filters/locationFiltersStoreModule'
import store from '@/store';

export default {
    props: {
        size: {
            type: String,
            required: false,
            default: 'md',
        },
        align: {
            type: String,
            required: false,
            default: 'horizontal',
        },
    },
    components: {
        BRow,
        BCol,
        BFormSelect,
        BFormSelectOption,
    },
    directives: {},
    setup(props, context) {        
        const filters = ref([])

        const levels = ref(JSON.parse(localStorage.getItem('userData')).levels)
        const locations = ref(JSON.parse(localStorage.getItem('userData')).locations)
        const children = ref([])
        const filtered = ref(null)

        const CROMIS_FILTERS_STORE_MODULE_NAME = 'cromis-filter'

        // Register module
        if (!store.hasModule(CROMIS_FILTERS_STORE_MODULE_NAME)) store.registerModule(CROMIS_FILTERS_STORE_MODULE_NAME, locationFiltersStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_FILTERS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_FILTERS_STORE_MODULE_NAME)
        })

        onMounted(async () => {
            if(levels.value.length > 0){
                levels.value = levels.value[0]

                filters.value.push({
                    level: levels.value.name.toLowerCase(),
                    location: null,
                    label: levels.value.name,
                    child: levels.value.children.length > 0
                                ? levels.value.children[0].name.toLowerCase()
                                : null,
                    locations: [],
                })

                let list = levels.value.children

                while(list.length > 0){
                    children.value.push(list[0])

                    if(list[0].children.length > 0){
                        filters.value.push({
                            level: list[0].name.toLowerCase(),
                            location: null,
                            label: list[0].name,
                            child: list[0].children.length > 0
                                        ? list[0].children[0].name.toLowerCase()
                                        : null,
                            locations: [],
                        })
                    } else {
                        filters.value.push({
                            level: list[0].name.toLowerCase(),
                            location: null,
                            label: list[0].name,
                            child: null,
                            locations: [],
                        })
                    }

                    list = list[0].children
                }
            }

            setFiltersLocations(locations.value)
        })

        const setFiltersLocations = (locations) => {
            for(let i = 0; i < locations.length; i++){
                for(let x = 0; x < filters.value.length; x++){
                    if(locations[i].level.name.toUpperCase() == filters.value[x].level.toUpperCase()){
                        filters.value[x].locations.push(locations[i])
                    }
                }
            }
        }

        const changeFilters = async (filteredLocation, index) => {
            if(!filteredLocation){
                for(let i = index + 1; i < filters.value.length; i++){
                    filters.value[i].locations.splice(0)
                    filters.value[i].location = null
                }

                if(index > 0){
                    filtered.value = filters.value[index - 1].location
                }
                else{
                    filtered.value = null
                }
            }
            else{
                if(filters.value[index + 1]){
                    filters.value[index + 1].locations.splice(0)
                }
                filteredLocation.children.map((child) => {
                    filters.value[index + 1].locations.push(child)
                })

                if(filteredLocation.children.length > 0){
                    filters.value[index + 1].location = null
                }

                filtered.value = filters.value[index].location
            }
            
            context.emit('filter', filtered.value)
        }

        return {
            levels,
            filters,
            changeFilters,
            filtered,
        }
    }
}
</script>